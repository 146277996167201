import React, { useMemo } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { lazy } from "react";
import { Header, Sidebar } from "../newComponents/molecules";
import BottomMenuNavigation from "./bottomMenuNavigation";
import { useDispatch, useSelector } from "react-redux";
import { getMeAction } from "../store/middlewares/web/profile/myProfile/me";

const NotFound = lazy(() => import("../pages/web/common/NotFound"));
const Register = lazy(() => import("../pages/auth/register/Register"));
const TwoFactorVerification = lazy(() =>
  import("../pages/auth/session/TwoFactorVerification")
);
const UserHomeScreen = lazy(() => import("../pages/web/fanSide/Home"));
const UserHomeProfilePost = lazy(() =>
  import("../pages/web/fanSide/UserHomeProfilePost")
);
const UserProfileScreen = lazy(() => import("../pages/web/fanSide/Profile"));
const UserHomePost = lazy(() => import("../pages/web/fanSide/UserHomePost"));
const UserBookmarks = lazy(() => import("../pages/web/fanSide/Bookmarks"));
const UserCollection = lazy(() => import("../pages/web/fanSide/Collection"));
const BecomeACreator = lazy(() =>
  import("../pages/web/fanSide/BecomeACreator")
);
const NewPost = lazy(() => import("../pages/web/fanSide/NewPost"));
const AllSuggestions = lazy(() => import("../pages/web/common/AllSuggestions"));
const CompleteProfile = lazy(() =>
  import("../pages/web/fanSide/CompleteProfile")
);
const Explore = lazy(() => import("../pages/web/fanSide/Explore"));
const CreatorHomeScreen = lazy(() => import("../pages/web/creatorSide/Home"));
const CreatorProfileScreen = lazy(() =>
  import("../pages/web/creatorSide/Profile")
);
const CreatorVaultScreen = lazy(() => import("../pages/web/creatorSide/Vault"));
const Queue = lazy(() => import("../pages/web/creatorSide/Queue"));
const ArchivePage = lazy(() => import("../pages/web/creatorSide/ArchivePage"));
const NotificationsPage = lazy(() =>
  import("../pages/web/creatorSide/NotificationsPage")
);
const Messages = lazy(() => import("../pages/web/Messages"));

import { useTheme } from "../newComponents/atoms";
import {
  Container,
  createTheme,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useIsDesktop from "../hooks/useIsDesktop";
import { webRoutes } from "./webNavigation";

const generatePath = (slug) => `/${slug}`;

// Memoize the Messages component to prevent re-renders
const MemoizedMessages = React.memo(Messages);
MemoizedMessages.displayName = "MemoizedMessages";

const getRouteConfig = (role) => {
  const publicRoutes = [
    { path: generatePath("profile/:username"), element: <UserHomeProfilePost /> },
  ];

  const sharedRoutes = [
    { path: generatePath("explore"), element: <Explore /> },
    { path: generatePath("all-suggestions"), element: <AllSuggestions /> },
    { path: generatePath(":username/status/:id"), element: <UserHomePost /> },
    { path: generatePath("bookmarks"), element: <UserBookmarks /> },
    { path: generatePath("user-lists"), element: <UserCollection /> },
    { path: generatePath("register"), element: <Register /> },
    { path: generatePath("notifications"), element: <NotificationsPage /> },
    {
      path: generatePath("messages"),
      element: <MemoizedMessages />,
      state: { from: location.pathname },
    },
    {
      path: generatePath("messages/:threadId"),
      element: <MemoizedMessages />,
      state: { from: location.pathname },
    },
    {
      path: generatePath("verify-2fa"),
      element: <TwoFactorVerification />,
    },
  ];

  const userSpecificRoutes = [
    { path: generatePath("home"), element: <UserHomeScreen /> },
    { path: generatePath("my-profile"), element: <UserProfileScreen /> },
    { path: generatePath("become-a-creator"), element: <BecomeACreator /> },
    { path: generatePath("post"), element: <NewPost /> },
    { path: generatePath("complete-profile"), element: <CompleteProfile /> },
    { path: generatePath("user/post"), element: <NewPost /> },
  ];

  const creatorSpecificRoutes = [
    { path: generatePath("home"), element: <CreatorHomeScreen /> },
    { path: generatePath("my-profile"), element: <CreatorProfileScreen /> },
    { path: generatePath("vault"), element: <CreatorVaultScreen /> },
    { path: generatePath("new-post"), element: <NewPost /> },
    { path: generatePath("edit-post/:id"), element: <NewPost /> },
    { path: generatePath("queue"), element: <Queue /> },
    { path: generatePath("archive"), element: <ArchivePage /> },
  ];

  return role === "creator"
    ? [...publicRoutes, ...sharedRoutes, ...creatorSpecificRoutes]
    : [...publicRoutes, ...sharedRoutes, ...userSpecificRoutes];
};

const UnifiedNavigation = React.memo(() => {
  const { webloggedInUser } = useSelector((state) => state.webauth);
  const { me } = useSelector((state) => state.me);
  const dispatch = useDispatch();
  const [showHeader, setShowHeader] = React.useState(true);
  const location = useLocation();

  // Add render counter using useRef
  const renderCount = React.useRef(0);
  React.useEffect(() => {
    renderCount.current += 1;
    console.log("UnifiedNavigation render count:", renderCount.current);
  });

  useDocumentTitle("YouOnly");
  const isDesktop = useIsDesktop();
  const { darkMode } = useTheme();

  // Memoize user details to prevent parsing on every render
  const user = useMemo(
    () => JSON.parse(localStorage.getItem("user-details")),
    []
  );
  const userType = useMemo(() => user?.userType || "user", [user]);

  // Memoize theme creation
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? "dark" : "light",
        },
      }),
    [darkMode]
  );

  // Memoize the routes configuration
  const routeConfig = useMemo(() => getRouteConfig(userType), [userType]);

  // Memoize path-based calculations
  const isWebRoute = useMemo(
    () => webRoutes.some((route) => location.pathname.startsWith(route.path)),
    [location.pathname]
  );

  const isMessageRoute = useMemo(
    () => location.pathname.startsWith("/messages"),
    [location.pathname]
  );

  // Effect to handle header visibility based on route changes
  React.useEffect(() => {
    const shouldShowHeader =
      user && !isWebRoute && (!isMessageRoute || (isDesktop && user));

    if (shouldShowHeader !== showHeader) {
      setShowHeader(shouldShowHeader);
    }
  }, [
    location.pathname,
    isDesktop,
    user,
    isWebRoute,
    isMessageRoute,
    showHeader,
  ]);

  React.useEffect(() => {
    const hasLoggedInUser = webloggedInUser || user;
    if (hasLoggedInUser && !me) {
      dispatch(getMeAction());
    }
  }, [webloggedInUser, user, me, dispatch]);

  // Memoize container styles
  const containerStyles = useMemo(
    () => ({
      display: "flex",
      minHeight: isMessageRoute ? "auto" : "100vh",
      overflow: isMessageRoute ? "hidden" : "auto",
      paddingTop: showHeader ? "84px" : "0",
    }),
    [isMessageRoute, showHeader]
  );

  // Debug info
  console.log({
    isWebRoute,
    renderCount: renderCount.current,
    pathname: location.pathname,
  });

  // Memoize the route elements
  const routeElements = useMemo(
    () => {
      const publicRoutes = [
        { path: generatePath("profile/:username"), element: <UserHomeProfilePost /> },
      ];

      return (
        <Routes>
          <Route path="/" element={<Navigate to="/home" replace />} />

          {webRoutes.map(({ path, element }, index) => (
            <Route key={index} path={path} element={element} />
          ))}

          {publicRoutes.map(({ path, element }, index) => (
            <Route key={index} path={path} element={element} />
          ))}

          {user ? (
            <>
              {routeConfig.map(({ path, element }, index) => (
                <Route key={index} path={path} element={element} />
              ))}
              <Route path="*" element={<NotFound />} />
            </>
          ) : (
            <>
              <Route path="*" element={<Navigate to="/login" replace />} />
            </>
          )}
        </Routes>
      );
    },
    [webRoutes, user, routeConfig]
  );

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {showHeader && <Header />}
      <Container
        maxWidth={!user ? false : "xl"}
        disableGutters={!user || !isDesktop}
        sx={containerStyles}
      >
        {(isDesktop && user && !isWebRoute) && <Sidebar type={userType} />}
        <div className="main-content flex-grow">{routeElements}</div>
      </Container>
      {!isDesktop && user && <BottomMenuNavigation userType={userType} />}
    </MuiThemeProvider>
  );
});

UnifiedNavigation.displayName = "UnifiedNavigation";

export default UnifiedNavigation;
