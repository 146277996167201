import axiosClient from "../../../../utils/api";

export const getNotifications = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/notifications",
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get Notifications Service", error);
    throw error;
  }
};
export const updateNotifications = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/notifications/updateNotification",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Update Notifications Service", error);
    throw error;
  }
};

export const getNotificationsPreferences = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/notifications/preferences",
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get Notifications Preferences Service", error);
    throw error;
  }
};

export const getDeclinedMediaNotifications = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/notifications/declined_media",
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get Declined Media Notifications Service", error);
    throw error;
  }
};
