import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Box } from "@mui/material";
import { getPostCommentsAction } from "../../../../../../store/middlewares/web/post/posts";
import { SingleComment } from "../../../../../atoms";
import LoadMoreComments from "../LoadMoreComments";
import { AddCommentTextArea } from "../../../../../atoms";

const PostCommentsList = ({
  comments,
  postId,
  postCreatorUserId,
  allowCommentsPagination,
  // for explore page
  // a user can click on creators
  // a creators can not click on user profiles
  isExplorePage,
}) => {
  const { commentsLoading, nextCommentsCursor, commentsHasNextPage } =
    useSelector((state) => state.posts);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!allowCommentsPagination) {
      return;
    }
    dispatch(
      getPostCommentsAction({
        postId,
        cursor:
          comments?.length > 0 ? comments[comments.length - 1].id : undefined,
      })
    );
  }, [dispatch, postId]);
  const handleLoadMore = () => {
    if (!commentsHasNextPage) return;
    if (commentsLoading) return;
    dispatch(
      getPostCommentsAction({
        postId,
        cursor: nextCommentsCursor,
      })
    );
  };
  if (commentsLoading && comments.length === 0) {
    return (
      <Box className="bg-white rounded-2xl py-4 flex justify-center">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        maxWidth: {
          xs: "100%",
          "@media (max-width: 480px)": {
            maxWidth: "290px",
          },
          "@media (max-width: 350px)": {
            maxWidth: "255px",
          },
        },
        margin: "0 auto", // todo: remove after better solution
      }}
    >
      {allowCommentsPagination && comments.length === 0 && (
        <h3 className="font-18 comment-title text-darkblue">No comments yet</h3>
      )}
      {comments.length > 0 && (
        <h3 className="font-18 comment-title text-darkblue">Comments</h3>
      )}
      <AddCommentTextArea postId={postId} />
      <div>
        {comments.map((comment) => (
          <SingleComment
            comment={comment}
            handleEdit={() => {}}
            postId={postId}
            postCreatorUserId={postCreatorUserId}
            key={comment?.id}
            isExplorePage={isExplorePage}
          />
        ))}
        {allowCommentsPagination && (
          <LoadMoreComments
            onLoadMore={handleLoadMore}
            loading={commentsLoading}
            comments={comments}
          />
        )}
      </div>
    </Box>
  );
};
export default PostCommentsList;
