import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { getMediaUserWiseAction } from "../../../../../store/middlewares/web/media/media";
import { CircularProgress } from "@mui/material";
import { ShowMedia } from "../../../../atoms";

export default function UserMedia({ type = "All" }) {
  //Show All Media Uploaded By user
  const dispatch = useDispatch();
  const { media, mediaLoading } = useSelector((state) => state.media);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    // /post/getMediaByUser
    dispatch(getMediaUserWiseAction({ userId: user?.id, type }));
  }, [dispatch]);

  return (
    <div className="mr-0">
      <div className="bg-white rounded-2xl py-4 px-2 w-full profile-media">
        <Grid container spacing={2} className="h-full">
          {mediaLoading && (
            <Grid
              item
              xs={12}
              className="flex justify-center"
              sx={{ margin: "1.5rem 0 !important" }}
            >
              <CircularProgress />
            </Grid>
          )}
          {media?.map((media, index) => {
            return (
              <ShowMedia postMedia={media?.postMedia} key={index} index={index} />
            );
          })}
          {media.length === 0 && !mediaLoading && (
            <Grid item xs={12}>
              <p className="text-center py-8">No Media To Display</p>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

// export default ProfileMedia;
