import React from "react";
import { Grid, Box } from "@mui/material";
import MediaItem from "../MediaItem";

const MediaSearchResults = ({
  items,
  onSelectItem,
  onMediaClick,
  isMultiDrag,
}) => {
  return (
    <Box 
      sx={{ 
        p: 3,
        height: 'calc(100vh - 260px)', // 100vh - (180px + 80px)
        overflowY: 'auto',
        scrollbarWidth: 'none', // Firefox
        msOverflowStyle: 'none', // IE and Edge
        '&::-webkit-scrollbar': {
          display: 'none', // Chrome, Safari and Opera
        },
      }}
    >
      <Grid
        container
        sx={{
          display: "grid",
          gap: 3,
          gridTemplateColumns: {
            xs: "repeat(2, 1fr)", // 2 columns on mobile
            sm: "repeat(2, 1fr)", // 3 columns on tablet and up
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          },
        }}
      >
        {items.map((item) => (
          <MediaItem
            key={item.id}
            id={item.id}
            type={item.type}
            thumbnail={item.thumbnail}
            date={item.date}
            selected={item.selected}
            onSelect={() => onSelectItem(item.id)}
            mediaStatus={item.mediaStatus}
            mediaId={item.mediaId}
            onMediaClick={onMediaClick}
            isMultiDrag={isMultiDrag}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default MediaSearchResults;
