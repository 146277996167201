import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Menu,
  MenuItem,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Typography,
} from "@mui/material";
import {
  setSortOption,
  setSortOrder,
} from "../../../../../../store/slices/creator/vault";

const VaultSortingMenu = ({ open, anchorEl, onClose }) => {
  const dispatch = useDispatch();

  const { sortOption, sortOrder } = useSelector((state) => state.vault);

  const handleOptionChange = (event) => {
    dispatch(setSortOption(event.target.value));
  };

  const handleOrderChange = (event) => {
    dispatch(setSortOrder(event.target.value));
  };

  const sortOptions = ["Default", "Name", "Recent", "Media", "Custom"];
  const orderOptions = ["Ascending", "Descending"];

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      slotProps={{
        paper: {
          style: {
            width: "100%", // Zauzmi punu širinu roditelja
            maxWidth: "250px", // Postavi maksimalnu širinu
            borderRadius: 16,
          },
        },
      }}
    >
      {/* Sort by Section */}
      {/* <FormControl sx={{ p: 2, pb: 0, width: "100%" }}>
        <FormLabel id="radio-buttons-group-options-label">Sort by</FormLabel>
        <RadioGroup
          aria-labelledby="radio-buttons-group-options-label"
          name="radio-buttons-group-options"
          value={sortOption}
          onChange={handleOptionChange}
        >
          {sortOptions.map((option) => (
            <MenuItem
              key={option}
              disableRipple
              sx={{
                width: "100%", // Zauzmi punu širinu
                padding: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                value={option}
                control={<Radio />}
                label={<Typography variant="body2">{option}</Typography>}
                sx={{
                  width: "100%",
                  margin: 0,
                  padding: 1,
                  display: "flex",
                  justifyContent: "start",
                }}
              />
            </MenuItem>
          ))}
        </RadioGroup>
      </FormControl>

      <Divider /> */}

      {/* Order by Section */}
      <FormControl sx={{ mt: 0, p: 2, width: "100%" }}>
        <FormLabel id="radio-buttons-group-order-label">Order by</FormLabel>
        <RadioGroup
          aria-labelledby="radio-buttons-group-order-label"
          name="radio-buttons-group-order"
          value={sortOrder}
          onChange={handleOrderChange}
        >
          {orderOptions.map((order) => (
            <MenuItem
              key={order}
              disableRipple
              sx={{
                width: "100%", // Zauzmi punu širinu
                padding: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                value={order}
                control={<Radio />}
                label={<Typography variant="body2">{order}</Typography>}
                sx={{
                  width: "100%",
                  margin: 0,
                  padding: 1,
                  display: "flex",
                  justifyContent: "start",
                }}
              />
            </MenuItem>
          ))}
        </RadioGroup>
      </FormControl>

      {/* <Divider />
      <FormControl sx={{ mt: 0, width: "100%", p: 2 }}>
        <MenuItem sx={{ p: 2, width: "100%", justifyContent: "center" }}>
          Customize Order
        </MenuItem>
      </FormControl> */}
    </Menu>
  );
};

export default VaultSortingMenu;
