import React, { useState } from "react";
import Button from "@mui/material/Button";
import GalleryBlueIcon from "./../../../../assets/images/gallery-blue.svg";
import VideoPlayIcon from "./../../../../assets/images/play-circle.svg";
import { Grid } from "@mui/material";
import VideoPlayer from "../../../molecules/web/common/media/VideoPlayer";

const ShowMedia = ({ postMedia, index }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  
  const showCount = (count) => {
    return (
      <div className="flex items-center gap-3 mt-3">
        <img src={GalleryBlueIcon} alt="GalleryBlueIcon" />
        <span className="text-black font-16 font-medium">
          {count > 10 ? count : `0${count}`}
        </span>
      </div>
    );
  };
  
  const mediaType = postMedia[0]?.media?.mediaType || "";

  return (
    <Grid item xs={6} md={4} lg={4} key={index}>
      <div className="gray-border p-0 m-0">
        <div
          className={`w-[232px] ${
            mediaType === "video" ? "relative" : ""
          } h-[212px] overflow-hidden p-0 m-0`}
        >
          {mediaType === "video" ? (
            <div className="rounded-[16px] overflow-hidden w-full h-full p-0 m-0">
              <VideoPlayer
                src={`${process.env.REACT_APP_AWS_URL}${postMedia[0]?.media?.s3Key}`}
                thumbnail={true}
                hidePlayButton={false}
                disableZoom={false}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
              />
            </div>
          ) : (
            <>
              <img
                src={`${process.env.REACT_APP_AWS_URL}${postMedia[0]?.media?.s3Key}`}
                alt={`post`}
                className="max-h-[212px] w-full object-contain rounded-[16px]"
              />
            </>
          )}
        </div>
        {mediaType === "video" ? (
          <div className="flex items-center justify-between gap-3">
            {showCount(postMedia?.length)}
            <Button
              variant="outlined"
              className="mt-3 light-blue-icon-btn font-semibold text-primary"
            >
              A
            </Button>
          </div>
        ) : (
          showCount(postMedia?.length)
        )}
      </div>
    </Grid>
  );
};

export default ShowMedia;
