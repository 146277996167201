import { Close } from "@mui/icons-material";
import { Box, IconButton, CircularProgress } from "@mui/material";
import React from "react";

const CreatePostImages = ({
  filesSrc,
  removeAble = false,
  removeImage,
  imageUploadProgress,
}) => {
  return (
    <>
      {filesSrc?.length > 0 && (
        <Box
          className="ml-3 mr-3"
          sx={{
            overflowX: "auto",
            overflowY: "hidden",
            height: "220px",
            minWidth: "calc(100% - 1.5rem)",
            whiteSpace: "nowrap",
          }}
        >
          {filesSrc?.map((file, index) => {
            const src = file.thumbnail || (file.fileURL ? file.fileURL : file);
            const isUploading = imageUploadProgress && imageUploadProgress[file.name] !== undefined && imageUploadProgress[file.name] < 100;
            return (
            <Box
              xs={3}
              className={`normal-padding relative h-[200px] w-[200px] ${
                index !== filesSrc?.length - 1 ? "mr-4" : ""
              }`}
              key={`image-${index}`}
              sx={{
                height: "200px",
                width: "200px",
                cursor: "pointer",
                display: "inline-block",
              }}
            >
              <img
                key={index}
                src={src}
                alt={`post-${index}`}
                className={`w-full object-cover h-full rounded-2xl`}
              />
              {removeAble && (
                <IconButton
                  disableRipple
                  className={"remove-icon border bg-black"}
                  size="small"
                  sx={{ color: "#fff" }}
                  onClick={() => removeImage(index)}
                >
                  <Close />
                </IconButton>
              )}
               {isUploading && ( 
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    borderRadius: '16px', 
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={imageUploadProgress[file.name]}
                    size={50} 
                    sx={{ color: 'white' }}
                  />
                   <span style={{ color: 'white', position: "absolute", top: "55%" }}>
                    {imageUploadProgress[file.name]}%
                  </span>
                </Box>
              )}
            </Box>
          )
        })}
        </Box>
      )}
    </>
  );
};

export default CreatePostImages;