import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  freeItems: [],
  paidItems: [],
  selectedItems: [],
  isBundlingActive: false,
  activeTab: "free",
  price: "3",
};

const mediaGallerySlice = createSlice({
  name: "mediaGallery",
  initialState,
  reducers: {
    setFreeItems: (state, action) => {
      state.freeItems = action.payload;
    },
    setPaidItems: (state, action) => {
      state.paidItems = action.payload;
    },
    addMediaItems: (state, action) => {
      // action.payload should have { items: [], targetTab: 'free' | 'price' }
      const { items, targetTab } = action.payload;
      if (targetTab === "free") {
        state.freeItems = [...state.freeItems, ...items];
      } else {
        state.paidItems = [...state.paidItems, ...items];
      }
    },
    moveItems: (state, action) => {
      // action.payload should have { indices: [], fromTab: 'free' | 'price', toTab: 'free' | 'price' }
      const { indices, fromTab, toTab } = action.payload;
      const sourceItems =
        fromTab === "free" ? state.freeItems : state.paidItems;
      const itemsToMove = indices.map((index) => sourceItems[index]);

      if (fromTab === "free") {
        state.freeItems = state.freeItems.filter(
          (_, index) => !indices.includes(index)
        );
        state.paidItems = [...state.paidItems, ...itemsToMove];
      } else {
        state.paidItems = state.paidItems.filter(
          (_, index) => !indices.includes(index)
        );
        state.freeItems = [...state.freeItems, ...itemsToMove];
      }
    },
    removeMediaItem: (state, action) => {
      // action.payload should have { index: number, tab: 'free' | 'price' }
      const { index, tab } = action.payload;
      if (tab === "free") {
        state.freeItems = state.freeItems.filter((_, i) => i !== index);
      } else {
        state.paidItems = state.paidItems.filter((_, i) => i !== index);
      }
    },
    setSelectedItems: (state, action) => {
      state.selectedItems = action.payload;
    },
    setBundlingActive: (state, action) => {
      state.isBundlingActive = action.payload;
      if (!action.payload) {
        state.selectedItems = [];
      }
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
      // Clear bundling state when switching tabs
      state.isBundlingActive = false;
      state.selectedItems = [];
    },
    setPrice: (state, action) => {
      state.price = action.payload;
    },
    syncWithMediaItems: (state, action) => {
      // Sync with incoming mediaItems prop
      const mediaItems = action.payload;
      state.freeItems = mediaItems.filter((item) => !item.price);
      state.paidItems = mediaItems.filter((item) => item.price);
    },
    resetMediaGallery: (state) => {
      // Reset all state properties to their initial values
      state.freeItems = initialState.freeItems;
      state.paidItems = initialState.paidItems;
      state.selectedItems = initialState.selectedItems;
      state.isBundlingActive = initialState.isBundlingActive;
      state.activeTab = initialState.activeTab;
      state.price = initialState.price;
    },
    addVaultMediaItems: (state, action) => {
      const newItems = action.payload;
      
      // Check for duplicates in both free and paid items
      const newUniqueItems = newItems.filter(newItem => 
        !state.freeItems.some(existingItem => existingItem.id === newItem.id) &&
        !state.paidItems.some(existingItem => existingItem.id === newItem.id)
      );

      if (state.activeTab === "free") {
        state.freeItems = [...state.freeItems, ...newUniqueItems];
      } else {
        state.paidItems = [...state.paidItems, ...newUniqueItems];
      }
    },
  },
});

export const {
  setFreeItems,
  setPaidItems,
  addMediaItems,
  moveItems,
  removeMediaItem,
  setSelectedItems,
  setBundlingActive,
  setActiveTab,
  setPrice,
  syncWithMediaItems,
  resetMediaGallery,
  addVaultMediaItems,
} = mediaGallerySlice.actions;

export default mediaGallerySlice.reducer;
