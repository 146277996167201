import axiosClient from "../../../../utils/api";

export const uploadToPresignedUrl = async (url, file) => {
  try {
    await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    });
  } catch (err) {
    console.log("Error uploading to presigned URL", err);
    throw err;
  }
};



//Delete Post By Admin
export const uploadFiles = async (payload) => {
  let headers = {
    "Content-Type": "multipart/form-data",
  };
  const formData = new FormData();
  for (var i = 0; i < payload.file.length; i++) {
    formData.append("file", payload.file[i]);
  }
  formData.append("uploadFor", payload.uploadFor);
  try {
    const response = await axiosClient.request({
      url: "/upload/file",
      method: "post",
      data: formData,
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Upload Files Service", error);
    throw error;
  }
};

export const generateMediaUploadPreSignedUrl = async (data) => {
  try {
    const response = await axiosClient.request({
      url: "/upload/generatePresignedUrl",
      method: "post",
      data,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Generate Media PreSigned URL Service", error);
    throw error;
  }
};