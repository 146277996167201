import React, { useRef, useEffect, memo } from "react";
import VideoPlayer from "../VideoPlayer";

const VideoPlayerWithTracking = memo(
  ({
    src,
    onDurationChange,
    handleWatchTimeUpdated,
    hidePlayButton,
    hideDurations,
    hideVolume,
    hideFullScreen,
    hideMenu,
    autoPlay,
    loop,
    style,
    disableZoom,
    inFocus,
    fullViewportHeight = false,
  }) => {
    const videoTimeRef = useRef(0);
    const lastUpdateTimeRef = useRef(0);
    const isPlayingRef = useRef(false);
    const timeUpdateCallbackRef = useRef(handleWatchTimeUpdated);

    useEffect(() => {
      timeUpdateCallbackRef.current = handleWatchTimeUpdated;
    }, [handleWatchTimeUpdated]);

    const handlePlay = () => {
      isPlayingRef.current = true;
      lastUpdateTimeRef.current = videoTimeRef.current;
    };

    const handlePause = () => {
      isPlayingRef.current = false;
      const timeDiff = videoTimeRef.current - lastUpdateTimeRef.current;
      if (timeDiff > 0) {
        timeUpdateCallbackRef.current(timeDiff);
        lastUpdateTimeRef.current = videoTimeRef.current;
      }
    };

    const handleEnded = () => {
      isPlayingRef.current = false;
      const timeDiff = videoTimeRef.current - lastUpdateTimeRef.current;
      if (timeDiff > 0) {
        timeUpdateCallbackRef.current(timeDiff);
        lastUpdateTimeRef.current = videoTimeRef.current;
      }
    };

    const combinedStyle = {
      ...(style || {}),
      height: fullViewportHeight ? "100vh" : style?.height || "100%",
    };

    return (
      <VideoPlayer
        src={src}
        onDurationChange={onDurationChange}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        hidePlayButton={hidePlayButton}
        hideDurations={hideDurations}
        hideVolume={hideVolume}
        hideFullScreen={hideFullScreen}
        hideMenu={hideMenu}
        autoPlay={autoPlay}
        loop={loop}
        style={combinedStyle}
        disableZoom={disableZoom}
        inFocus={inFocus}
        fullViewportHeight={fullViewportHeight}
      />
    );
  }
);

VideoPlayerWithTracking.displayName = "VideoPlayerWithTracking";

export default VideoPlayerWithTracking;
