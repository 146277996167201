// https://aws.amazon.com/blogs/networking-and-content-delivery/image-optimization-using-amazon-cloudfront-and-aws-lambda/ 🧠

export const awsImageLoader = (path, width, quality) => {
  if (!path) return "";

  const transformatorUrl =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_CLOUDFRONT_IMAGE_TRANSFORMATOR_URL;
  if (!transformatorUrl || !(width || quality))
    // eslint-disable-next-line no-undef
    return `${process.env.REACT_APP_AWS_URL}${path}`;

  const queryParams = `?format=auto${width ? `&width=${width}` : ""}${
    quality ? `&quality=${quality}` : ""
  }`;
  const newUrl = `${transformatorUrl}/${path}${queryParams}`;

  return newUrl;
};
