import { Error } from '@mui/icons-material'
import { Box, Grid } from '@mui/material'
import React from 'react'

const ProfileButtons = ({ endAdornment, title, startAdornment, hasOneValue = true, hasValue = true, value = "", disabled = false }) => {
    //Custom Buttons in My Profile Section
    const handleClick = (event) => {
        if (disabled) {
            event.preventDefault();
            return;
        }
        if (endAdornment?.props?.onClick) {
            endAdornment.props.onClick(event);
        }
    };

    return (
        <Grid item xs={12} md={6} lg={4}>
            <Box className='mb-3 lg:mr-4 md:mr-2 '>
                <Box style={{
                    borderRadius: "16px",
                    backgroundColor: "#f4f4f4",
                    border: `1px solid ${!hasValue ? "#F64027" : "transparent"}`,
                    height: "53px",
                    opacity: disabled ? 0.7 : 1,
                    cursor: disabled ? 'default' : 'pointer'
                }}
                    className="px-3 flex justify-between items-center lightgray-400 min-w-[200px]"
                    onClick={handleClick}
                >
                    <Box className='p-0 m-0 flex items-center gap-x-2 profile-button' >
                        {startAdornment && startAdornment}
                        <Box className="flex flex-col items-start justify-center">
                            <p className='profile-button-text font-medium text-darkblue'>{title}</p>
                            {(hasOneValue && value) && <span className='profile-button-text ' style={{ fontSize: '14px' }}>{value}</span>}
                        </Box>
                    </Box>
                    <Box 
                        className="flex gap-x-4 items-center justify-end w-7 h-7"
                    >
                        {!hasValue && <Error color='error' />}
                        {endAdornment && endAdornment}
                    </Box>
                </Box>
            </Box>
        </Grid >
    )
}

export default ProfileButtons
