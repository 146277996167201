import React, { useEffect, useRef } from "react";
import StoryViewer from "../../../newComponents/molecules/web/common/StoryModal/StoryViewer";
import UserBlank from "../../../assets/images/blank.png";
import { createPostView } from "../../../store/services/web/post/posts.services";
import { pollMediaStatus } from "../../../store/services/web/media/media.service";
import { awsImageLoader } from "../../../utils/awsImageLoader";

const HomeStories = ({ story }) => {
  const { postMedia, users, hasViewed, allStories } = story;
  const [isViewed, setIsViewed] = React.useState(hasViewed);
  const [openStory, setOpenStory] = React.useState(false);
  const [localPostMedia, setLocalPostMedia] = React.useState(postMedia);
  const polledMediaIds = useRef(new Set());
  
  const approvedMedia = localPostMedia.filter(media => media.status === "approved");

  useEffect(() => {
    const checkPendingMedia = async () => {
      const pendingMedia = postMedia.filter(media =>
        media.status === "pending" && !polledMediaIds.current.has(media.id)
      );

      pendingMedia.forEach(media => {
        polledMediaIds.current.add(media.id);
      });

      for (const media of pendingMedia) {
        try {
          const updatedMedia = await pollMediaStatus(media.id);

          if (updatedMedia.status === "approved") {
            setLocalPostMedia(prev => 
              prev.map(item => 
                item.id === media.id 
                  ? { ...item, status: updatedMedia.status, fileURL: awsImageLoader(updatedMedia.fileURL) }
                  : item
              )
            );
          }
        } catch (error) {
          console.error('Error polling media status:', error);
        }
      }
    };

    checkPendingMedia();
  }, []);

  const handleOnStoryClick = () => {
    if (!isViewed) {
      setIsViewed(true);
      allStories.forEach(story => {
        if (!story.hasViewed) {
          createPostView(story.id);
        }
      });
    }
    setOpenStory(true);
  };

  const handleOnStoryClose = () => {
    setOpenStory(false);
  };

  if (approvedMedia.length === 0) return null;

  const updatedAllStories = allStories.map(s => {
    if (s.id === story.id) {
      return { ...s, postMedia: localPostMedia };
    }
    return s;
  });

  return (
    <>
      <div
        className="user-img relative cursor-pointer"
        onClick={handleOnStoryClick}
      >
        <img src={approvedMedia[0].fileURL} alt={users.username} />
        <img
          src={users?.profileImage ?? UserBlank}
          alt={users.username}
          className={`user-position ${!isViewed && "active"}`}
        />
        <p className="mb-0 user-text" title={users.username}>
          {users.username}
        </p>
      </div>
      {openStory && (
        <StoryViewer
          images={updatedAllStories.reduce((acc, currentStory) => [
            ...acc, 
            ...(currentStory.postMedia.filter(media => media.status === "approved"))
          ], [])}
          onClose={handleOnStoryClose}
          user={users}
          createdAt={story.createdAt}
          hasLiked={story.hasLiked}
          likesCount={story.likesCount}
          postId={story.id}
          allStories={updatedAllStories}
        />
      )}
    </>
  );
};

export default HomeStories;
