import axiosClient from "../../../../utils/api";

//Get User Wise Media
export const getMediaUserWise = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/post/getMediaByUser",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get Media User Wise Service", error);
    throw error;
  }
};

export const pollMediaStatus = async (mediaId) => {
  try {
    let media;
    do {
      const response = await axiosClient.request({
        url: `/upload/media/${mediaId}`,
        method: 'get'
      });
      media = response.data.response;
      if (media.status === 'pending') {
        await new Promise(resolve => setTimeout(resolve, 5000));
      }
    } while (media.status === 'pending');

    const fileURL = media.mediaType === 'video'
      ? await getSignedUrl(media.s3Key)
      : media.s3Key;

    return {
      id: media.id,
      fileType: media.fileType,
      mediaType: media.mediaType,
      fileURL,
      status: media.status
    };
  } catch (error) {
    console.log('Error polling media status', error);
    throw error;
  }
};
