import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardMedia,
  Typography,
  Checkbox,
  Skeleton,
} from "@mui/material";
import AudioIcon from "../../../../../assets/SVG/VaultTabs/AudioIcon";
import PhotoIcon from "../../../../../assets/SVG/VaultTabs/PhotoIcon";
import VideoIcon from "../../../../../assets/SVG/VaultTabs/VideoIcon";
import GifIcon from "../../../../../assets/SVG/VaultTabs/GifIcon";
import VideoPlayer from "../media/VideoPlayer";
import AudioPlayer from "../media/AudioPlayer";
import { formatDuration } from "../../../../../utils/formatDuration";
import { useDraggable } from "@dnd-kit/core";
import { pollMediaStatus } from '../../../../../store/services/web/media/media.service';

const IMAGE_HEIGHT = 150;

const MediaItem = ({
  type,
  thumbnail,
  date,
  selected,
  onSelect,
  id,
  isMultiDrag,
  onMediaClick,
  mediaStatus,
  mediaId
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [duration, setDuration] = useState(null);
  const [media, setMedia] = useState({ thumbnail, status: mediaStatus });

  useEffect(() => {
    const checkMediaStatus = async () => {
      if (mediaStatus === 'pending') {
        try {
          const updatedMedia = await pollMediaStatus(mediaId);
          
            setMedia(prev=>{
              return {
                ...prev,
                status: updatedMedia.status
              }
            });
            
        } catch (error) {
          console.error('Error polling media status:', error);
        }
      }
    };

    checkMediaStatus();
  }, [id, mediaStatus]);

  if (!media) {
    return null;
  }

  const handleDurationChange = (newDuration) => {
    setDuration(newDuration);
  };

  const handleMediaClick = (e) => {
    e.stopPropagation();
    onMediaClick?.({ type, thumbnail: media.thumbnail, id });
  };

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: id,
      disabled: !selected,
    });

  const style = {
    transform:
      isDragging && !isMultiDrag && transform
        ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
        : undefined,
    opacity: isDragging && !isMultiDrag ? 0 : 1,
    cursor: selected ? "grab" : "pointer",
  };

  const getIcon = () => {
    switch (type) {
      case "video":
        return <VideoIcon fontSize="small" />;
      case "audio":
        return <AudioIcon fontSize="small" />;
      case "image":
        return <PhotoIcon fontSize="small" />;
      case "gif":
        return <GifIcon fontSize="small" />;
      default:
        return null;
    }
  };

  const renderMedia = () => {
    if (type === "video") {
      return (
        <Box
          sx={{
            width: "100%",
            height: 150,
            borderRadius: 1,
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            backgroundColor: "black",
            cursor: "pointer",
            position: "relative",
          }}
          onClick={handleMediaClick}
        >
          <VideoPlayer
            src={media.thumbnail}
            onDurationChange={handleDurationChange}
            static={true}
          />
          {media.status === "pending" && renderPendingOverlay()}
          {media.status === "rejected" && renderPendingOverlay("Approval rejected")}
        </Box>
      );
    }

    if (type === "audio") {
      return (
        <Box
          sx={{
            width: 150,
            height: IMAGE_HEIGHT,
            borderRadius: 2,
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#f5f5f5",
            position: "relative",
          }}
        >
          <AudioPlayer
            src={media.thumbnail}
            onDurationChange={handleDurationChange}
          />
          {media.status === "pending" && renderPendingOverlay()}
          {media.status === "rejected" && renderPendingOverlay("Approval rejected")}
        </Box>
      );
    }

    return (
      <Box sx={{ position: "relative" }}>
        {!imageLoaded && (
          <Skeleton
            variant="rectangular"
            width={150}
            height={150}
            sx={{ borderRadius: 2 }}
          />
        )}
        <Box
          onClick={handleMediaClick}
          sx={{ cursor: "pointer", position: "relative" }}
        >
          <CardMedia
            component="img"
            image={media.thumbnail}
            alt={`${type} thumbnail`}
            sx={{
              borderRadius: 2,
              display: imageLoaded ? "block" : "none",
              width: "100%",
              height: IMAGE_HEIGHT,
            }}
            onLoad={() => setImageLoaded(true)}
          />
          {media.status === "pending" && renderPendingOverlay()}
          {media.status === "rejected" && renderPendingOverlay("Approval rejected")}
        </Box>
      </Box>
    );
  };

  const renderPendingOverlay = (message = "Pending Approval") => (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        color: "white",
        padding: "4px 8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
      }}
    >
      <Typography variant="caption" sx={{ fontWeight: 500 }}>
        {message}
      </Typography>
    </Box>
  );

  return (
    <Card
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onClick={onSelect}
      sx={{
        position: "relative",
        borderRadius: 2,
        border: selected ? "2px solid #1976d2" : "2px solid #F2F2F2",
        p: 1,
        cursor: "pointer",
        "&:hover": {
          boxShadow: 4,
        },
      }}
      elevation={0}
    >
      {renderMedia()}
      <Checkbox
        checked={selected}
        sx={{
          position: "absolute",
          top: 20,
          right: 20,
          zIndex: 1,
          bgcolor: "rgba(255, 255, 255, 0.7)",
          borderRadius: "25%",
          padding: "4px",
          "& .MuiSvgIcon-root": {
            fontSize: 20,
          },
        }}
        readOnly
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={1}
      >
        <Box display="flex" alignItems="center" gap={1}>
          {getIcon()}
          {duration && (
            <Typography variant="body2">{formatDuration(duration)}</Typography>
          )}
        </Box>
        <Typography variant="body2" color="text.secondary">
          {date}
        </Typography>
      </Box>
    </Card>
  );
};

MediaItem.propTypes = {
  type: PropTypes.oneOf(["video", "audio", "image", "gif"]).isRequired,
  thumbnail: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onMediaClick: PropTypes.func,
  id: PropTypes.string.isRequired,
  isMultiDrag: PropTypes.bool,
  mediaStatus: PropTypes.string,
};

MediaItem.defaultProps = {
  selected: false,
  onSelect: () => {},
  onMediaClick: () => {},
  isMultiDrag: false,
  mediaStatus: null,
};

export default MediaItem;
