import React, { useEffect, useState, useCallback } from "react";
import InfiniteScroll from "../../../../atoms/InfiniteScroll";
import {
  getFilteredUserList,
  getUserListCollectionUsers,
} from "../../../../../store/services/admin/users/users";
import {
  setFilteredUsers,
  setUsersLoading,
  resetUsers,
} from "../../../../../store/slices/common/users";
import { useSelector, useDispatch } from "react-redux";
import ScreenMirroringIcon from "./../../../../../assets/images/screenmirroring.svg";
import ActiveUserIcon from "./../../../../../assets/images/active-user.svg";
import TimerIcon from "./../../../../../assets/images/timer.svg";
import BlockedUserIcon from "./../../../../../assets/images/blockuserIcon.svg";
import Masonry from "@mui/lab/Masonry";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, CircularProgress, Grid, Button } from "@mui/material";
import UserListFolders from "../../../../molecules/web/userLists/UserListFolders";
import DisplayUserInCollection from "../../../../molecules/web/userLists/DisplayUserInCollection";
import { NoItemsInCollection } from "../../../../molecules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const buttonStyles = {
  height: "40px",
  minHeight: "40px !important",
  borderRadius: "20px !important",
  padding: "0 16px !important",
  textTransform: "none",
  fontSize: "14px !important",
  fontWeight: "500 !important",
  border: "none !important",
  width: "100%",
  "&.MuiButton-contained": {
    backgroundColor: "#2799F6 !important",
    color: "#FFFFFF !important",
    "& .MuiButton-startIcon": {
      "& img": {
        filter: "brightness(0) invert(1)",
      },
    },
    "&:hover": {
      backgroundColor: "#2799F6 !important",
    },
  },
  "&.MuiButton-outlined": {
    backgroundColor: "#2799F60D !important",
    color: "#2799F6 !important",
    "& .MuiButton-startIcon": {
      "& img": {
        filter: "none",
      },
    },
    "&:hover": {
      backgroundColor: "#2799F60D !important",
    },
  },
};

const swiperStyles = {
  ".user-tabs-swiper-container": {
    width: "100%",
    padding: "0 24px",
    marginBottom: "16px",
    "@media (max-width: 600px)": {
      padding: "0 16px",
    },
  },
  ".user-tabs-swiper": {
    ".swiper-button-next, .swiper-button-prev": {
      display: "none",
    },
    ".swiper-slide": {
      width: "auto",
    },
    ".swiper-wrapper": {
      "@media (max-width: 400px)": {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
        gap: "8px",
      },
    },
  },
};

const UsersTab = ({ active, setActive }) => {
  const [selectedTab, setSelectedTab] = useState("all");
  const dispatch = useDispatch();
  const { users, usersLoading, hasNextPage, nextCursor } = useSelector(
    (state) => state.users
  );
  const [usersToDisplay, setUsersToDisplay] = useState([]);

  const loadUsers = useCallback(
    async (append = false) => {
      if (append && (!hasNextPage || usersLoading)) {
        return;
      }

      if (!append) {
        dispatch(setUsersLoading(true));
      }
      try {
        if (selectedTab === "all") {
          if (!active?.id) return;
          const response = await getUserListCollectionUsers({
            folderId: active?.id,
            cursor: append ? nextCursor : undefined,
          });
          dispatch(
            setFilteredUsers({
              users: response.response.users,
              nextCursor: response.response.nextCursor,
              hasNextPage: response.response.hasNextPage,
              append,
            })
          );
        } else {
          const filterTypeMap = {
            active: "subscribedFromMeActive",
            expired: "subscribedFromMeExpired",
            restricted: "restrictedByMe",
            blocked: "blockedByMe",
            favorites: "favoritedByMe",
            fans: "subscribedToMe",
          };

          const response = await getFilteredUserList({
            filterType: filterTypeMap[selectedTab],
            cursor: append ? nextCursor : undefined,
          });

          dispatch(
            setFilteredUsers({
              users: response.response.users,
              nextCursor: response.response.nextCursor,
              hasNextPage: response.response.hasNextPage,
              append,
            })
          );
        }
      } catch (error) {
        console.error("Failed to load users:", error);
      } finally {
        dispatch(setUsersLoading(false));
      }
    },
    [selectedTab, active?.id, nextCursor, hasNextPage, usersLoading, dispatch]
  );

  useEffect(() => {
    dispatch(resetUsers());
    loadUsers(false);
  }, [selectedTab, active?.id]);

  useEffect(() => {
    setUsersToDisplay(users);
  }, [users]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabs = [
    {
      icon: <img src={ScreenMirroringIcon} alt="All" />,
      label: `All (${
        active?.userCount < 10
          ? `0${active?.userCount}`
          : active?.userCount
          ? active?.userCount
          : 0
      })`,
      value: "all",
    },
    {
      icon: <img src={ActiveUserIcon} alt="Active" />,
      label: `Active (${
        active?.totalActiveUser < 10
          ? `0${active?.totalActiveUser}`
          : active?.totalActiveUser
          ? active?.totalActiveUser
          : 0
      })`,
      value: "active",
    },
    {
      icon: <img src={TimerIcon} alt="Expired" />,
      label: `Expired (${
        active?.totalExpiredUser < 10
          ? `0${active?.totalExpiredUser}`
          : active?.totalExpiredUser
          ? active?.totalExpiredUser
          : 0
      })`,
      value: "expired",
    },
    {
      icon: <img src={BlockedUserIcon} alt="Restricted" />,
      label: `Restricted (${
        active?.totalRestrictedUser < 10
          ? `0${active?.totalRestrictedUser}`
          : active?.totalRestrictedUser
          ? active?.totalRestrictedUser
          : 0
      })`,
      value: "restricted",
    },
    {
      icon: <img src={BlockedUserIcon} alt="Blocked" />,
      label: `Blocked (${
        active?.totalBlockedUser < 10
          ? `0${active?.totalBlockedUser}`
          : active?.totalBlockedUser
          ? active?.totalBlockedUser
          : 0
      })`,
      value: "blocked",
    },
    {
      label: "Favorites",
      value: "favorites",
    },
    {
      label: "Fans",
      value: "fans",
    },
  ];

  return (
    <TabContext value={selectedTab}>
      <Box className="mb-5" sx={{ '@media (max-width: 600px)': { maxWidth: '400px' } }}>
        <Box sx={swiperStyles}>
          <div className="user-tabs-swiper-container">
            <Swiper
              modules={[Navigation]}
              navigation={false}
              slidesPerView="auto"
              spaceBetween={8}
              className="user-tabs-swiper"
            >
              {tabs.map((tab) => (
                <SwiperSlide key={tab.value}>
                  <Button
                    variant={
                      selectedTab === tab.value ? "contained" : "outlined"
                    }
                    onClick={(e) => handleChange(e, tab.value)}
                    startIcon={tab.icon}
                    sx={buttonStyles}
                  >
                    {tab.label}
                  </Button>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Box>
      </Box>

      <Grid container spacing={1} className="h-full mb-4 fans-message">
        <Grid item xs={12} md={12} lg={8}>
          <div className="light-gray-bg lg:mr-4 post-no-padding mb-4">
            <TabPanel value={selectedTab}>
              <div className="h-full mb-4 fans-message">
                {usersLoading ? (
                  <div className="py-12 w-full rounded-2xl flex justify-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    {usersToDisplay?.length === 0 ? (
                      <NoItemsInCollection />
                    ) : (
                      <Masonry
                        columns={{ xs: 1, sm: 1, md: 1, lg: 2 }}
                        spacing={2}
                      >
                        {usersToDisplay?.map((user, index) => {
                          return (
                            <DisplayUserInCollection
                              key={index}
                              user={user}
                              index={index}
                              folderId={active?.id}
                            />
                          );
                        })}
                      </Masonry>
                    )}
                  </>
                )}
              </div>
              <InfiniteScroll
                onLoadMore={() => loadUsers(true)}
                loading={usersLoading}
                isInitialDataLoaded={true}
                dataLength={usersToDisplay.length}
                threshold={0.5}
              />
            </TabPanel>
          </div>
        </Grid>

        {selectedTab === "all" && (
          <UserListFolders
            mediaType={selectedTab}
            type={"users"}
            active={active}
            setActive={setActive}
            users={users}
          />
        )}
      </Grid>
    </TabContext>
  );
};

export default UsersTab;
