import { Avatar, AvatarGroup, IconButton } from "@mui/material";
import React from "react";
import { openModal } from "../../../../../store/slices/common/modal";
import { Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import ModalDeleteBookmark from "../ModalDeleteBookmark";
import NoPostsForBookmark from "../NoPostsForBookmark";

const BookMarkFolders = ({ active, setActive }) => {
  const { posts } = useSelector((state) => state.posts);
  const { folders } = useSelector((state) => state.folders);

  const dispatch = useDispatch();

  return (
    <div className="light-gray-bg p-4 creator-home-module">
      <div className="flex justify-between flex-wrap gap-3 border-b pb-3">
        <p className="font-18 font-medium">Recent</p>
      </div>

      {folders?.map((folder) => {
        const avatars = folder.avatars ?? [];
        return (
          <div
            className={`customer-box cursor-pointer ${
              active?.id === folder.id ? "active" : ""
            } mt-5`}
            key={folder.id}
            onClick={() => setActive(folder)}
          >
            <div className="flex justify-between gap-3 flex-wrap">
              <div>
                <p className="text-darkblue font-medium">
                  {folder?.folderName}
                </p>
                <AvatarGroup
                  max={3}
                  sx={{
                    "& .MuiAvatarGroup-avatar": {
                      width: 40,
                      height: 25,
                      borderRadius: 4,
                      fontSize: "0.75rem",
                      "&:last-child": {
                        marginLeft: -1,
                      },
                    },
                  }}
                  className="mt-3"
                >
                  {avatars.slice(0, 3).map((avatar, index) => (
                    <Avatar
                      key={index}
                      src={`${process.env.REACT_APP_AWS_URL}${avatar.s3Key}`}
                      variant="rounded"
                      sx={{ backgroundColor: "#FFFFFF" }}
                    />
                  ))}
                </AvatarGroup>
              </div>
              <div className="flex gap-4 items-start">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      openModal({
                        title: "DELETE",
                        hasCloseBtn: true,
                        content: <ModalDeleteBookmark folderId={folder?.id} />,
                      })
                    );
                  }}
                  disableRipple
                  sx={{ padding: "0" }}
                >
                  <Delete color="error" />
                </IconButton>
              </div>
            </div>
          </div>
        );
      })}
      {folders?.length === 0 && <NoPostsForBookmark />}
    </div>
  );
};

export default BookMarkFolders;
