import * as React from "react";
// import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
// import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import logo from "./../../../../../assets/images/logo.svg";
// import SearchIcon from "./../../../../../assets/images/search-normal.svg";
// import CartIcon from "./../../../../../assets/images/shopping-cart.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import EmailVerificationBanner from "../EmailVerificationBanner/index";
import MessagesIcon from "@mui/icons-material/Message";

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(3),
//     width: "auto",
//   },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   "& .MuiInputBase-input": {
//     height: "38px",
//     padding: theme.spacing(1, 1, 1, 0),
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("md")]: {
//       width: "20ch",
//     },
//   },
// }));

export default function PrimarySearchAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const navigate = useNavigate();

  const { me } = useSelector((state) => state.me);

  const handleLogoClick = () => {
    navigate("/home");
  };

  // const [open, setOpen] = React.useState(false);

  // const toggleDrawer = (newOpen) => () => {
  //   setOpen(newOpen);
  // };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem sx={{ gap: 1 }} onClick={() => navigate("/messages")}>
        <IconButton
          size="large"
          sx={{
            backgroundColor: "#EFF8FE !important",
            borderRadius: "16px !important",
          }}
          aria-label="messages"
          color="inherit"
        >
          <MessagesIcon size={24} color="#292D32" />
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem sx={{ gap: 1 }} onClick={() => navigate("/notifications")}>
        <IconButton
          size="large"
          aria-label="show notifications"
          color="inherit"
          sx={{
            backgroundColor: "#EFF8FE !important",
            borderRadius: "16px !important",
          }}
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem sx={{ gap: 1 }} onClick={() => navigate("/my-profile")}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          sx={{
            backgroundColor: "#EFF8FE !important",
            borderRadius: "16px !important",
          }}
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#fff !important",
          color: "#000 !important",
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1) !important",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        {me && !me.isIdentityVerified && <EmailVerificationBanner />}
        <div className="container">
          <Toolbar
            sx={{
              height: "76px",
              paddingLeft: "0 !important",
              paddingRight: "0 !important",
            }}
          >
            <div
              className="mr-4 min-w-36 cursor-pointer"
              onClick={handleLogoClick}
            >
              <img src={logo} alt="logo" width={175} />
            </div>

            <Box sx={{ flexGrow: 1 }} />
            {/* <Search>
              <SearchIconWrapper>
                <img src={SearchIcon} alt="search Icon" />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search"
                id="search"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <IconButton
                size="large"
                sx={{
                  backgroundColor: "#EFF8FE !important",
                  borderRadius: "16px !important",
                }}
                aria-label="show 4 new mails"
                color="inherit"
              >
                <img src={CartIcon} alt="cart icon" width={30} />
              </IconButton>
            </Box> */}
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </div>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
