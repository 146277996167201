import { createSlice } from "@reduxjs/toolkit";
import {
  addFolderAction,
  addUserListFolderAction,
  deleteFolderAction,
  deleteUserListFolderAction,
  getFolderAction,
  getUserListFolderAction,
} from "../../../middlewares/web/folders/folders";

export const foldersSlice = createSlice({
  name: "folders",
  initialState: {
    foldersLoading: false,
    folders: [],
  },
  reducers: {
    updateCountOnRestrictUser: (state, action) => {
      let { folderId, restrict } = action.payload;
      let { folders } = state;
      let tempArray = folders?.map((folder) => {
        if (folder?.id === folderId) {
          if (restrict) {
            return {
              ...folder,
              totalRestrictedUser: folder?.totalRestrictedUser + 1,
              totalActiveUser: folder?.totalActiveUser - 1,
            };
          }
          return {
            ...folder,
            totalRestrictedUser: folder?.totalRestrictedUser - 1,
            totalActiveUser: folder?.totalActiveUser + 1,
          };
        }
        return { ...folder };
      });
      state.folders = tempArray;
    },
    updateCountOnBlockedUser: (state, action) => {
      let { folderId, blocked } = action.payload;
      let { folders } = state;
      let tempArray = folders?.map((folder) => {
        if (folder?.id === folderId) {
          if (blocked) {
            return {
              ...folder,
              totalBlockedUser: folder?.totalBlockedUser + 1,
              totalActiveUser:
                folder?.totalActiveUser > 0
                  ? folder?.totalActiveUser - 1
                  : folder?.totalActiveUser,
            };
          }
          return {
            ...folder,
            totalBlockedUser:
              folder?.totalBlockedUser > 0
                ? folder?.totalBlockedUser - 1
                : folder?.totalBlockedUser,
            totalActiveUser: folder?.totalActiveUser + 1,
          };
        }
        return { ...folder };
      });
      state.folders = tempArray;
    },
    bookmarkUpdateCount: (state, action) => {
      let { folderId } = action.payload;
      let { folders } = state;
      let tempArray = folders?.map((folder) => {
        if (folder?.id === folderId) {
          return {
            ...folder,
            totalPosts: folder?.totalPosts - 1,
            bookmarksCount: folder?.bookmarksCount - 1,
          };
        } else {
          return {...folder}
        }
      });
      state.folders = tempArray
    },
  },
  extraReducers: (builder) => {
    builder
      //get bookmark folder
      .addCase(getFolderAction.pending, (state) => {
        state.foldersLoading = true;
        state.folders = null;
      })
      .addCase(getFolderAction.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.foldersLoading = true;
        state.folders = response;
      })
      .addCase(getFolderAction.rejected, (state) => {
        state.foldersLoading = false;
        state.folders = null;
      })
      //get User List folder
      .addCase(getUserListFolderAction.pending, (state) => {
        state.foldersLoading = true;
        state.folders = null;
      })
      .addCase(getUserListFolderAction.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.foldersLoading = false;
        state.folders = response;
      })
      .addCase(getUserListFolderAction.rejected, (state) => {
        state.foldersLoading = false;
        state.folders = null;
      })
      //Add User List folder
      .addCase(addUserListFolderAction.pending, (state) => {
        state.foldersLoading = false;
      })
      .addCase(addUserListFolderAction.fulfilled, (state, action) => {
        const {
          response: { folder },
        } = action.payload;
        let { folders } = state;
        if (folders?.length > 0) {
          folders.push(folder);
        } else {
          folders = [folder];
        }
        state.foldersLoading = true;
        state.folders = folders;
      })
      .addCase(addUserListFolderAction.rejected, (state) => {
        state.foldersLoading = false;
      })
      //Add Bookmark folder
      .addCase(addFolderAction.pending, (state) => {
        state.foldersLoading = false;
      })
      .addCase(addFolderAction.fulfilled, (state, action) => {
        const {
          response: { folder },
        } = action.payload;
        let { folders } = state;
        if (folders?.length > 0) {
          folders.push(folder);
        } else {
          folders = [folder];
        }
        state.foldersLoading = true;
        state.folders = folders;
      })
      .addCase(addFolderAction.rejected, (state) => {
        state.foldersLoading = false;
      })
      //Delete Bookmark folder
      .addCase(deleteFolderAction.pending, (state) => {
        state.foldersLoading = true;
      })
      .addCase(deleteFolderAction.fulfilled, (state, action) => {
        state.foldersLoading = false;
        state.folders = state.folders.filter(folder => folder.id !== action.payload);
      })
      .addCase(deleteFolderAction.rejected, (state) => {
        state.foldersLoading = false;
      })
      //Delete User List folder
      .addCase(deleteUserListFolderAction.pending, (state) => {
        state.foldersLoading = false;
      })
      .addCase(deleteUserListFolderAction.fulfilled, (state, action) => {
        const { folderId } = action.payload;
        state.foldersLoading = true;
        state.folders = state?.folders?.filter(
          (folder) => folder?.id !== folderId
        );
      })
      .addCase(deleteUserListFolderAction.rejected, (state) => {
        state.foldersLoading = false;
      });
  },
});

export const { updateCountOnRestrictUser, updateCountOnBlockedUser, bookmarkUpdateCount } =
  foldersSlice.actions;

export default foldersSlice.reducer;
