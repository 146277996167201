import React, { useState, useCallback, useEffect } from "react";
import { PageHeader } from "../../../../newComponents/atoms";
import { useTheme } from "@mui/material/styles";
import { TabContext, TabPanel } from "@mui/lab";
import Button from "@mui/material/Button";
import {
  Grid,
  Box,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
  Modal,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import vaultTabs from "../../../../utils/vaultTabs";
import PlusIcon from "@mui/icons-material/AddCircleOutline";
import MinusIcon from "@mui/icons-material/RemoveCircleOutline";
import listmenublack from "../../../../assets/images/listmenublack.svg";
import listmenuwhite from "../../../../assets/images/listmenuwhite.svg";
import MediaSearchResults from "../../../../newComponents/molecules/web/common/MediaSearchResults";
import VaultSearchHeader from "./components/VaultSearchHeader";
import VaultList from "./components/VaultList";
import { useDropzone } from "react-dropzone";
import vaultService from "../../../../store/services/web/vault/vault.service";
import { CircularProgress, Alert } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getVaultMediaAction } from "../../../../store/middlewares/web/vault/vault";
import {
  clearVaultMedia,
  toggleMediaSelection,
  addMediaToVault,
  setVaultFolders,
  setVaultFoldersLoading,
  setVaultFoldersError,
  clearSelectedMedia,
} from "../../../../store/slices/creator/vault";
import { openModal } from "../../../../store/slices/common/modal";
import ModalCreateFolder from "../../../../newComponents/molecules/web/vault/ModalCreateFolder";
import { getColor } from "../../../../utils/colors";
import {
  DndContext,
  DragOverlay,
  useSensor,
  useSensors,
  MouseSensor,
} from "@dnd-kit/core";
import MediaItem from "../../../../newComponents/molecules/web/common/MediaItem";
import { snapCenterToCursor } from "@dnd-kit/modifiers";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Video from "yet-another-react-lightbox/plugins/video";
import VaultSortingMenu from "./components/VaultSortingMenu";

// Add mobile detection utility
const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

const tabToMediaType = {
  0: null,
  1: "image",
  2: "gif",
  3: "video",
  4: "audio",
};

// Update swiperStyles to include responsive behavior and proper padding
const swiperStyles = {
  ".vault-tabs-swiper-container": {
    width: "100%",
    padding: "0 24px",
    marginBottom: "16px",
    "@media (max-width: 600px)": {
      padding: "0 16px",
    },
  },
  ".vault-tabs-swiper": {
    ".swiper-button-next, .swiper-button-prev": {
      display: "none",
    },
    ".swiper-slide": {
      width: "auto",
    },
    ".swiper-wrapper": {
      "@media (max-width: 600px)": {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
        gap: "8px",
      },
    },
  },
};

// Add buttonStyles constant
const buttonStyles = {
  height: "40px",
  minHeight: "40px !important",
  borderRadius: "20px !important",
  padding: "0 16px !important",
  textTransform: "none",
  fontSize: "14px !important",
  fontWeight: "500 !important",
  border: "none !important",
  width: "100%", // Make buttons take full width on mobile
  "&.MuiButton-contained": {
    backgroundColor: "#2799F6 !important",
    color: "#FFFFFF !important",
    "& .MuiButton-startIcon svg": {
      color: "#FFFFFF",
    },
    "&:hover": {
      backgroundColor: "#2799F6 !important",
    },
  },
  "&.MuiButton-outlined": {
    backgroundColor: "#2799F60D !important",
    color: "#2799F6 !important",
    "& .MuiButton-startIcon svg": {
      color: "#2799F6",
    },
    "&:hover": {
      backgroundColor: "#2799F60D !important",
    },
  },
};

export default function Vault({ isModal = false, onAddMedia }) {
  const [uploadMediaVisible, setUploadMediaVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState("0");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploadError, setUploadError] = useState(null);
  const [selectedFolderId, setSelectedFolderId] = useState("all");
  const [draggedMediaGroup, setDraggedMediaGroup] = useState([]);
  const { sortOption, sortOrder } = useSelector((state) => state.vault);
  const [lightboxState, setLightboxState] = useState({
    isOpen: false,
    currentMedia: null,
    currentIndex: 0,
  });
  // Add state for mobile confirmation modal
  const [mobileConfirmation, setMobileConfirmation] = useState({
    isOpen: false,
    folderId: null,
    folderName: "",
  });

  const { darkMode } = useTheme();
  const dispatch = useDispatch();
  const { media, selectedMedia, folders } = useSelector((state) => state.vault);
  const selectedMediaFromRedux = useSelector(
    (state) => state.vault.selectedMedia
  );

  const isMultiDrag = draggedMediaGroup.length > 1;

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const sortingMenuOpen = Boolean(menuAnchorEl);

  const [mediaSortMenuAnchorEl, setMediaSortMenuAnchorEl] = useState(null);
  const mediaSortMenuOpen = Boolean(mediaSortMenuAnchorEl);

  // Add cleanup effect
  useEffect(() => {
    return () => {
      // Clear local states
      setUploadMediaVisible(false);
      setSelectedTab("0");
      setAnchorEl(null);
      setIsUploading(false);
      setUploadProgress({});
      setUploadError(null);
      setSelectedFolderId("all");
      setDraggedMediaGroup([]);
      setLightboxState({
        isOpen: false,
        currentMedia: null,
        currentIndex: 0,
      });
      setMobileConfirmation({
        isOpen: false,
        folderId: null,
        folderName: "",
      });

      // Clear Redux states
      dispatch(clearVaultMedia());
    };
  }, [dispatch]);

  const handleOpenUpload = () => {
    setUploadMediaVisible(true);
  };

  const handleCloseUpload = () => {
    setUploadMediaVisible(false);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    dispatch(clearVaultMedia());
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onUploadSuccess = useCallback(
    (response) => {
      setUploadMediaVisible(false);
      dispatch(addMediaToVault(response));
    },
    [dispatch]
  );

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setIsUploading(true);
      setUploadError(null);
      let hasError = false;

      for (const file of acceptedFiles) {
        try {
          const fileType = file.type;
          const mediaType = fileType.startsWith("video/")
            ? "video"
            : fileType.startsWith("audio/")
            ? "audio"
            : fileType === "image/gif"
            ? "gif"
            : fileType.startsWith("image/")
            ? "image"
            : null;

          if (!mediaType) {
            throw new Error("Unsupported file type");
          }

          const { preSignedUploadUrl, id } = (
            await vaultService.generateMediaUploadPreSignedUrl({
              fileType,
            })
          ).response;

          try {
            await axios.put(preSignedUploadUrl, file, {
              headers: {
                "Content-Type": file.type,
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploadProgress((prev) => ({
                  ...prev,
                  [file.name]: percentCompleted,
                }));
              },
            });

            const addToVaultResponse = await vaultService.addToVault({
              mediaId: id,
            });

            onUploadSuccess(addToVaultResponse);

            setUploadProgress((prev) => ({
              ...prev,
              [file.name]: 100,
            }));
          } catch (uploadError) {
            console.error("Error uploading file:", uploadError);
            hasError = true;
            throw new Error(`Failed to upload ${file.name}`);
          }
        } catch (error) {
          console.error("Upload error:", error);
          hasError = true;
          setUploadError(`Failed to upload ${file.name}: ${error.message}`);
        }
      }

      setIsUploading(false);

      if (!hasError) {
        setTimeout(() => {
          setUploadProgress({});
          setUploadMediaVisible(false);
        }, 1000);
      }
    },
    [onUploadSuccess]
  );

  const handleMediaSelect = useCallback(
    (mediaId) => {
      dispatch(toggleMediaSelection(mediaId));
    },
    [dispatch]
  );

  const handleFolderSelect = (folderId) => {
    if (isMobileDevice() && selectedMedia.length > 0) {
      // Find the folder name
      const folder = folders.find((f) => f.id === folderId);
      if (folder) {
        setMobileConfirmation({
          isOpen: true,
          folderId: folderId,
          folderName: folder.name,
        });
      }
    } else {
      setSelectedFolderId(folderId);
    }
  };

  const handleMobileConfirmation = (confirmed) => {
    if (confirmed && mobileConfirmation.folderId && selectedMedia.length > 0) {
      vaultService
        .addMediaToFolder({
          folderId: mobileConfirmation.folderId,
          vaultMediaToAdd: selectedMedia,
        })
        .then(() => {
          fetchVaultFolders();
          const mediaType = tabToMediaType[selectedTab];
          dispatch(
            getVaultMediaAction({
              pageSize: 50,
              ...(selectedFolderId !== "all" && {
                folderId: selectedFolderId,
              }),
              ...(mediaType && { mediaType }),
            })
          );
          // Clear selected media after successful addition
          dispatch(clearSelectedMedia());
        })
        .catch(console.error);
    }
    setMobileConfirmation({
      isOpen: false,
      folderId: null,
      folderName: "",
    });
  };

  const handleAddSelected = () => {
    if (onAddMedia && selectedMedia.length > 0) {
      const selectedFiles = transformedMedia.filter((item) =>
        selectedMedia.includes(item.id)
      );
      onAddMedia(selectedFiles);
    }
  };

  const handleDragStart = ({ active }) => {
    const allSelected = selectedMediaFromRedux.map((id) =>
      transformedMedia.find((item) => item.id === id)
    );

    const dragged =
      allSelected.length > 0
        ? allSelected
        : [transformedMedia.find((item) => item.id === active.id)];

    setDraggedMediaGroup(dragged);
  };

  const handleDragEnd = ({ over }) => {
    setDraggedMediaGroup([]);
    if (over && draggedMediaGroup.length > 0) {
      const mediaIdsToAdd = draggedMediaGroup.map((item) => item.id);

      vaultService
        .addMediaToFolder({
          folderId: over.id,
          vaultMediaToAdd: mediaIdsToAdd,
        })
        .then(() => {
          fetchVaultFolders();
          if (selectedFolderId) {
            const mediaType = tabToMediaType[selectedTab];
            dispatch(
              getVaultMediaAction({
                pageSize: 50,
                ...(selectedFolderId !== "all" && {
                  folderId: selectedFolderId,
                }),
                ...(mediaType && { mediaType }),
              })
            );
          }
        })
        .catch(console.error);
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 25,
      },
    })
  );

  const handleMediaClick = (mediaItem) => {
    const compatibleMedia = transformedMedia.filter(
      (item) => item.type === "image" || item.type === "video"
    );

    const currentIndex = compatibleMedia.findIndex(
      (item) => item.id === mediaItem.id
    );

    const slides = compatibleMedia.map((item) =>
      item.type === "video"
        ? {
            type: "video",
            width: 1280,
            height: 720,
            sources: [{ src: item.thumbnail }],
            mediaId: item.id,
            selected: selectedMedia.includes(item.id),
          }
        : {
            src: item.thumbnail,
            width: 1280,
            height: 720,
            mediaId: item.id,
            selected: selectedMedia.includes(item.id),
          }
    );

    setLightboxState({
      isOpen: true,
      currentMedia: slides,
      currentIndex: currentIndex >= 0 ? currentIndex : 0,
    });
  };

  const CustomCheckbox = () => {
    const currentSlide =
      lightboxState.currentMedia?.[lightboxState.currentIndex];
    const currentMediaId = currentSlide?.mediaId;
    const isItemSelected = currentMediaId
      ? selectedMedia.includes(currentMediaId)
      : false;

    return (
      <button
        type="button"
        className="yarl__button"
        onClick={(e) => {
          e.stopPropagation();
          if (currentMediaId) {
            handleMediaSelect(currentMediaId);
          }
        }}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          color: "var(--yarl__color_button)",
          backgroundColor: isItemSelected ? "#2196F3" : "transparent",
          padding: "8px 16px",
          borderRadius: "4px",
          transition: "background-color 0.2s ease",
        }}
      >
        <span>Select</span>
      </button>
    );
  };

  const fetchVaultFolders = useCallback(async () => {
    dispatch(setVaultFoldersLoading());
    try {
      const response = await vaultService.getAllVaultFolders();
      dispatch(setVaultFolders(response?.response || []));
    } catch (error) {
      dispatch(setVaultFoldersError(error.message || "Failed to load folders"));
    }
  }, [dispatch]);

  useEffect(() => {
    const mediaType = tabToMediaType[selectedTab];
    dispatch(
      getVaultMediaAction({
        pageSize: 50,
        ...(selectedFolderId &&
          selectedFolderId !== "all" && { folderId: selectedFolderId }),
        ...(mediaType && { mediaType }),
        orderBy: sortOrder === "Ascending" ? "asc" : "desc",
      })
    );

    fetchVaultFolders();

    return () => dispatch(clearVaultMedia());
  }, [selectedFolderId, selectedTab, sortOrder]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
      "video/*": [],
      "audio/*": [],
      "image/gif": [],
    },
  });

  // Transform media data
  const transformedMedia =
    media?.map((item) => ({
      id: item.vaultId,
      type: item.mediaType,
      thumbnail: item.fileURL,
      date: formatDate(item.createdAt),
      selected: selectedMedia.includes(item.vaultId),
      mediaStatus: item.status,
      mediaId: item.mediaId,
    })) || [];

  const handleSortingMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleSortingMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleMediaSortMenuClick = (event) => {
    setMediaSortMenuAnchorEl(event.currentTarget);
  };

  const handleMediaSortMenuClose = () => {
    setMediaSortMenuAnchorEl(null);
  };

  const getSortedFolders = useCallback(() => {
    const sortedFolders = [...folders];
    const allMediaIndex = sortedFolders.findIndex(
      (folder) => folder.id === "all"
    );
    let allMediaFolder = null;

    if (allMediaIndex !== -1) {
      allMediaFolder = sortedFolders.splice(allMediaIndex, 1)[0];
    }

    const filteredFolders = searchQuery
      ? sortedFolders.filter((folder) =>
          folder.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : sortedFolders;

    if (sortOption === "Name") {
      filteredFolders.sort((a, b) => {
        const comparison = a.name.localeCompare(b.name);
        return sortOrder === "Ascending" ? comparison : -comparison;
      });
    }

    if (allMediaFolder) {
      filteredFolders.unshift(allMediaFolder);
    }

    return filteredFolders;
  }, [folders, sortOption, sortOrder, searchQuery]);

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      sensors={sensors}
      modifiers={[snapCenterToCursor]}
    >
      <DragOverlay>
        {draggedMediaGroup.length > 1 ? (
          // Više medija: jednostavan box
          <div
            style={{
              width: 150,
              height: 50,
              background: "#2196F3",
              border: "1px solid #ccc",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#FFF",
              cursor: "grabbing",
            }}
          >
            Moving {draggedMediaGroup.length} items
          </div>
        ) : draggedMediaGroup.length === 1 ? (
          // Jedan medij: prikaži MediaItem
          <MediaItem
            id={draggedMediaGroup[0].id}
            selected={true}
            mediaId={draggedMediaGroup[0].id}
            mediaStatus={draggedMediaGroup[0].mediaStatus}
            type={draggedMediaGroup[0].type}
            thumbnail={draggedMediaGroup[0].thumbnail}
            date={draggedMediaGroup[0].date}
            onSelect={() => {}}
          />
        ) : null}
      </DragOverlay>
      <Grid container className="h-full fans-profile">
        <Grid item sm={12} md={8}>
          <div>
            {!isModal && (
              <PageHeader
                title={`All Media${uploadMediaVisible ? " uploading..." : ""}`}
                rightToolbar={
                  <Box>
                    <Button
                      sx={{ width: 220 }}
                      type="submit"
                      variant="contained"
                      onClick={
                        !uploadMediaVisible
                          ? handleOpenUpload
                          : handleCloseUpload
                      }
                      startIcon={
                        !uploadMediaVisible ? <PlusIcon /> : <MinusIcon />
                      }
                    >
                      {!uploadMediaVisible
                        ? "Upload Media"
                        : "Cancel upload..."}
                    </Button>
                  </Box>
                }
              />
            )}

            {uploadMediaVisible && (
              <Box
                sx={{
                  mt: 2,
                  mb: 2,
                  p: 3,
                  border: "2px dashed grey",
                  borderRadius: 2,
                  bgcolor: isDragActive ? "rgba(0,0,0,0.1)" : "transparent",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {isUploading ? (
                  <Box>
                    <Typography>Uploading files...</Typography>
                    {Object.entries(uploadProgress).map(
                      ([fileName, progress]) => (
                        <Box key={fileName} sx={{ mt: 1 }}>
                          <Typography variant="body2">
                            {fileName}: {progress}%
                          </Typography>
                          <CircularProgress
                            variant="determinate"
                            value={progress}
                          />
                        </Box>
                      )
                    )}
                  </Box>
                ) : (
                  <Typography>
                    {isDragActive
                      ? "Drop the files here..."
                      : "Drag 'n' drop files here, or click to select files"}
                  </Typography>
                )}
                {uploadError && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {uploadError}
                  </Alert>
                )}
              </Box>
            )}

            <Box
              sx={{
                ...(isModal && {
                  paddingBottom: "80px",
                }),
                ...swiperStyles,
              }}
            >
              <div className="flex flex-wrap post-tab bg-white rounded-2xl p-3 mt-5 w-full mb-3">
                <TabContext value={selectedTab}>
                  <Box className="vault-tabs-swiper-container">
                    <Swiper
                      modules={[Navigation]}
                      navigation={false}
                      slidesPerView="auto"
                      spaceBetween={8}
                      className="vault-tabs-swiper"
                    >
                      {vaultTabs?.map((tab) => (
                        <SwiperSlide key={tab?.label}>
                          <Button
                            variant={
                              selectedTab === tab.value
                                ? "contained"
                                : "outlined"
                            }
                            onClick={(e) => handleTabChange(e, tab.value)}
                            startIcon={tab.icon}
                            sx={buttonStyles}
                          >
                            {tab.label}
                          </Button>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Box>

                  {/* TabPanels */}
                  <TabPanel
                    value="0"
                    className="w-full"
                    sx={{ padding: "0 !important" }}
                  ></TabPanel>
                  <TabPanel
                    value="1"
                    className="w-full"
                    sx={{ padding: "0 !important" }}
                  ></TabPanel>
                  <TabPanel
                    value="3"
                    className="w-full"
                    sx={{ padding: "0 !important" }}
                  ></TabPanel>
                  <TabPanel
                    value="4"
                    className="w-full"
                    sx={{ padding: "0 !important" }}
                  ></TabPanel>
                </TabContext>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mb: 2,
                    width: "100%",
                  }}
                >
                  <Button
                    variant="text"
                    onClick={handleMediaSortMenuClick}
                    endIcon={
                      <img
                        src={darkMode ? listmenuwhite : listmenublack}
                        alt="Sort"
                        width={20}
                      />
                    }
                  ></Button>
                </Box>
                <MediaSearchResults
                  items={transformedMedia}
                  onSelectItem={handleMediaSelect}
                  onMediaClick={handleMediaClick}
                  isMultiDrag={isMultiDrag}
                />
                {isModal && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      padding: 2,
                      width: "100%",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      backgroundColor: darkMode
                        ? getColor("darkSecondary")
                        : getColor("white"),
                      borderTop: (theme) =>
                        `1px solid ${theme.palette.divider}`,
                      zIndex: 1,
                      marginBottom: 0,
                    }}
                  >
                    <Typography variant="body1">
                      {selectedMedia.length} Selected
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        width: 150,
                        height: 47,
                        minHeight: "44px !important",
                        ml: 2,
                      }}
                      size="small"
                      onClick={handleAddSelected}
                    >
                      Add
                    </Button>
                  </Box>
                )}
              </div>
            </Box>
          </div>
        </Grid>
        <Grid item sm={12} md={4}>
          <Box sx={{ ml: 2 }}>
            <VaultSearchHeader />
            <Box className="post-tab bg-white rounded-2xl w-full mt-5">
              <FormControl
                className="w-full mb-4"
                variant="outlined"
                sx={{ p: 1.5, pb: 0 }}
              >
                <Button
                  variant="contained"
                  sx={{ my: 2 }}
                  onClick={() =>
                    dispatch(
                      openModal({
                        title: "Create Vault Folder",
                        content: <ModalCreateFolder />,
                        hasCloseBtn: true,
                      })
                    )
                  }
                >
                  Create Vault Folder
                </Button>
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mb: 4,
                  width: "100%",
                  padding: "0px 16px",
                }}
                id="vault-search-input-container"
              >
                <OutlinedInput
                  sx={{ width: "100%" }}
                  placeholder="Search folders"
                  id="outlined-adornment-weight"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  endAdornment={
                    <InputAdornment position="end" sx={{ mr: 2 }}>
                      <IconButton onClick={handleSortingMenuClick} edge="end">
                        <img
                          src={darkMode ? listmenuwhite : listmenublack}
                          alt="List Menu"
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
              <VaultList
                items={getSortedFolders().map((f) => ({
                  ...f,
                  isSelected: f.id === selectedFolderId,
                }))}
                onFolderSelect={handleFolderSelect}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Lightbox
        open={lightboxState.isOpen}
        close={() => {
          setLightboxState({
            isOpen: false,
            currentMedia: null,
            currentIndex: 0,
          });
        }}
        slides={lightboxState.currentMedia || []}
        plugins={[Zoom, Video]}
        carousel={{ finite: false }}
        animation={{ fade: 0 }}
        controller={{ closeOnBackdropClick: true }}
        index={lightboxState.currentIndex}
        on={{
          view: ({ index }) => {
            if (index !== lightboxState.currentIndex) {
              setLightboxState((prev) => ({
                ...prev,
                currentIndex: index,
              }));
            }
          },
        }}
        video={{
          autoPlay: false,
          controls: true,
        }}
        zoom={{
          maxZoomPixelRatio: 3,
          zoomInMultiplier: 2,
          doubleTapDelay: 300,
          doubleClickDelay: 300,
          doubleClickMaxStops: 2,
          wheelZoomDistanceFactor: 100,
          pinchZoomDistanceFactor: 100,
          scrollToZoom: true,
        }}
        toolbar={{
          buttons: [<CustomCheckbox key="select-checkbox" />, "close"],
        }}
        styles={{
          root: {
            "--yarl__color_backdrop": "rgba(0, 0, 0, 0.75)",
            "--yarl__color_button": "#fff",
            "--yarl__color_button_active": "#fff",
          },
        }}
      />

      {/* Add mobile confirmation modal */}
      <Modal
        open={mobileConfirmation.isOpen}
        onClose={() => handleMobileConfirmation(false)}
        aria-labelledby="mobile-confirmation-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Add {selectedMedia.length} file
            {selectedMedia.length !== 1 ? "s" : ""} to{" "}
            {mobileConfirmation.folderName}?
          </Typography>
          <Box
            sx={{ mt: 2, display: "flex", justifyContent: "flex-end", gap: 2 }}
          >
            <Button
              onClick={() => handleMobileConfirmation(false)}
              color="primary"
            >
              No, Cancel
            </Button>
            <Button
              onClick={() => handleMobileConfirmation(true)}
              variant="contained"
              color="primary"
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>

      <VaultSortingMenu
        open={sortingMenuOpen}
        anchorEl={menuAnchorEl}
        onClose={handleSortingMenuClose}
      />

      <VaultSortingMenu
        open={mediaSortMenuOpen}
        anchorEl={mediaSortMenuAnchorEl}
        onClose={handleMediaSortMenuClose}
      />
    </DndContext>
  );
}
