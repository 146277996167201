import React, { useState, useRef, useEffect } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";
import useIsDesktop from "../../../../../../hooks/useIsDesktop";

const VideoPlayer = ({
  src,
  onDurationChange,
  onPlay,
  onPause,
  onEnded,
  thumbnail = false,
  onReady,
  disableZoom = false,
  hidePlayButton = false,
  hideDurations = false,
  hideVolume = false,
  hideFullScreen = false,
  hideMenu = false,
  autoPlay = false,
  loop = false,
  style,
  inFocus = true,
  fullViewportHeight = false,
  static: isStatic = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(16 / 9);

  const isDesktop = useIsDesktop();

  // Handle focus changes
  useEffect(() => {
    if (videoRef.current && !inFocus) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    } else if (videoRef.current && inFocus && autoPlay && !isStatic) {
      videoRef.current.play().catch(() => {
        // Autoplay failed - handle if needed
      });
    }
  }, [inFocus, autoPlay, isStatic]);

  // Create a custom controlsList based on hidden elements
  const getControlsList = () => {
    const controls = [];
    if (hideMenu) controls.push("nodownload");
    if (hideFullScreen) controls.push("nofullscreen");
    return controls.join(" ");
  };

  const handleLightboxClose = () => {
    setIsOpen(false);
    if (videoRef.current) {
      videoRef.current.currentTime = currentTime;
      if (isPlaying) {
        videoRef.current.play();
      }
    }
  };

  const handleVideoEvents = (event) => {
    const video = event.target;
    setAspectRatio(video.videoWidth / video.videoHeight);

    if (onDurationChange) {
      video.addEventListener("durationchange", () =>
        onDurationChange(video.duration)
      );
    }
    if (onPlay) {
      video.addEventListener("play", () => {
        setIsPlaying(true);
        onPlay();
      });
    }
    if (onPause) {
      video.addEventListener("pause", () => {
        setIsPlaying(false);
        onPause();
      });
    }
    if (onEnded) {
      video.addEventListener("ended", () => {
        setIsPlaying(false);
        onEnded();
      });
    }
    if (onReady) {
      onReady();
    }
  };

  useEffect(() => {
    if (videoRef.current && autoPlay) {
      videoRef.current.play().catch(() => {
        // Autoplay failed - handle if needed
      });
    }
  }, [autoPlay]);

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const slides = [
    {
      type: "video",
      width: 1280,
      height: Math.round(1280 / aspectRatio),
      sources: [{ src, type: "video/mp4" }],
      poster: thumbnail || undefined,
    },
  ];

  // Determine if we should show controls at all
  const showControls =
    !thumbnail &&
    !isStatic &&
    !(
      hidePlayButton &&
      hideDurations &&
      hideVolume &&
      hideFullScreen &&
      hideMenu
    );

  return (
    <>
      <div
        className="w-full relative group"
        style={{
          backgroundColor: "black",
          ...(isDesktop
            ? {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }
            : {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                height: fullViewportHeight ? "100vh" : "auto",
              }),
          ...(style || {}),
        }}
      >
        <div
          className="relative"
          style={
            isDesktop
              ? {
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }
              : {
                  width: "100%",
                  paddingTop: `${(1 / aspectRatio) * 100}%`,
                }
          }
        >
          <video
            ref={videoRef}
            src={src}
            className={
              isDesktop
                ? "h-full w-auto max-w-full"
                : "absolute inset-0 w-full h-full"
            }
            style={{
              objectFit: "contain",
            }}
            controls={showControls}
            controlsList={getControlsList()}
            autoPlay={autoPlay && !isStatic}
            loop={loop}
            playsInline
            onLoadedMetadata={handleVideoEvents}
            onTimeUpdate={handleTimeUpdate}
          />
        </div>
      </div>

      {!disableZoom && (
        <Lightbox
          open={isOpen}
          close={handleLightboxClose}
          slides={slides}
          plugins={[Video]}
          carousel={{ finite: true }}
          animation={{ fade: 0 }}
          render={{
            buttonPrev: () => null,
            buttonNext: () => null,
          }}
          video={{
            autoPlay: isPlaying,
            controls: !hidePlayButton,
            controlsList: getControlsList(),
            startTime: currentTime,
            style: {
              backgroundColor: "#000",
            },
          }}
        />
      )}
    </>
  );
};

VideoPlayer.displayName = "VideoPlayer";

export default VideoPlayer;
