import { createSlice } from "@reduxjs/toolkit";
import { getMyExplorePostsAction } from "../../../middlewares/web/post/posts";

const initialState = {
  myExplorePosts: [],
  myExplorePostsLoading: false,
  myExplorePostsCursor: null,
  myExplorePostsHasNextPage: true,
};

export const myExplorePostsSlice = createSlice({
  name: "myExplorePosts",
  initialState,
  reducers: {
    resetMyExplorePosts: (state) => {
      state.myExplorePosts = [];
      state.myExplorePostsCursor = null;
      state.myExplorePostsHasNextPage = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyExplorePostsAction.pending, (state) => {
        state.myExplorePostsLoading = true;
      })
      .addCase(getMyExplorePostsAction.fulfilled, (state, action) => {
        const { response, status } = action.payload;
        if (status === 1) {
          const { explorePosts } = response;
          state.myExplorePosts = state.myExplorePostsCursor 
            ? [...state.myExplorePosts, ...explorePosts.posts]
            : explorePosts.posts;
          state.myExplorePostsCursor = explorePosts.nextCursor;
          state.myExplorePostsHasNextPage = explorePosts.hasNextPage;
        }
        state.myExplorePostsLoading = false;
      })
      .addCase(getMyExplorePostsAction.rejected, (state) => {
        state.myExplorePostsLoading = false;
      });
  },
});

export const { resetMyExplorePosts } = myExplorePostsSlice.actions;

export default myExplorePostsSlice.reducer; 