import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useHotkeys } from "react-hotkeys-hook";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";

const DisplayImagesInPost = ({ filesSrc = [] }) => {
  const [activeIndex] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const toggleFullScreen = () => setIsFullScreen(!isFullScreen);

  useHotkeys("esc", () => {
    if (isFullScreen) {
      setIsFullScreen(false);
    }
  });

  useEffect(() => {
    if (isFullScreen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isFullScreen]);

  const [reorderedSlides, setReorderedSlides] = useState([]);

  useEffect(() => {
    if (filesSrc && filesSrc.length > 0) {
      const updatedSlides = filesSrc
        .slice(activeIndex % filesSrc.length)
        .concat(filesSrc.slice(0, activeIndex % filesSrc.length))
        .map((imageSrc) => ({ src: imageSrc }));
      setReorderedSlides(updatedSlides);
    } else {
      setReorderedSlides([]);
    }
  }, [activeIndex, filesSrc]);

  return (
    <Box>
      {/* Main Image */}
      {filesSrc.length > 0 && (
        <Box
          sx={{
            width: "100%",
            maxWidth: "100%",
            aspectRatio: "1 / 1",
            margin: "0 auto",
            overflow: "hidden",
            borderRadius: "8px",
            marginBottom: "10px",
          }}
          onClick={toggleFullScreen}
        >
          <img
            src={filesSrc[activeIndex]}
            alt="Active Image"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      )}

      {/* Full Screen Lightbox */}
      <Lightbox
        open={isFullScreen}
        plugins={[Zoom, Counter]}
        counter={{
          container: {
            style: {
              top: "unset",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
            },
          },
        }}
        close={() => setIsFullScreen(false)}
        slides={reorderedSlides}
        carousel={{ padding: 0 }}
        styles={{
          container: { backgroundColor: "rgba(0, 0, 0, 0.8)" }, // TODO: change to unified color
        }}
        zoom={{
          maxZoomPixelRatio: 3,
          zoomInMultiplier: 2,
          doubleTapDelay: 300,
          doubleClickDelay: 300,
          doubleClickMaxStops: 2,
          wheelZoomDistanceFactor: 100,
          pinchZoomDistanceFactor: 100,
          scrollToZoom: true,
        }}
      />
    </Box>
  );
};

export default DisplayImagesInPost;
