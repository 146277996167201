import { createSlice } from "@reduxjs/toolkit";
import { getActiveExplorePostsAction } from "../../../middlewares/web/post/posts";
import { addComentsToPost, removeCommentsAction } from "../../../middlewares/web/post/posts";

export const explorePostsSlice = createSlice({
  name: "explorePosts",
  initialState: {
    explorePosts: [],
    loading: false,
    error: false,
    success: false,
    message: "",
    cursor: null,
    hasNextPage: true,
    isInitialPostsLoaded: false,
  },
  extraReducers(builder) {
    builder
      .addCase(getActiveExplorePostsAction.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
        state.message = "";
      })
      .addCase(getActiveExplorePostsAction.fulfilled, (state, action) => {
        const newPosts = action.payload.response.posts;
        const existingPostIds = new Set(state.explorePosts.map(post => post.id));
        const filteredPosts = newPosts.filter(post => !existingPostIds.has(post.id));

        state.explorePosts = [
          ...state.explorePosts,
          ...filteredPosts,
        ];
        state.loading = false;
        state.error = false;
        state.success = true;
        state.message = action.payload.message;
        state.cursor = action.payload.response.nextCursor;
        state.hasNextPage = action.payload.response.hasNextPage;
        state.isInitialPostsLoaded = true;
      })
      .addCase(getActiveExplorePostsAction.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.success = false;
        state.message = action.payload.message;
      })
      .addCase(addComentsToPost.fulfilled, (state, action) => {
        const postIndex = state.explorePosts.findIndex(
          (post) => post.id === action.payload.response.postId
        );
        if (postIndex !== -1) {
          if (!state.explorePosts[postIndex].post_comment) {
            state.explorePosts[postIndex].post_comment = [];
          }
          state.explorePosts[postIndex] = {
            ...state.explorePosts[postIndex],
            post_comment: [...state.explorePosts[postIndex].post_comment, action.payload.response.comment],
            _count: {
              ...state.explorePosts[postIndex]._count,
              post_comment: (state.explorePosts[postIndex]._count?.post_comment || 0) + 1
            }
          };
        }
      })
      .addCase(removeCommentsAction.fulfilled, (state, action) => {
        const { postId, commentId } = action.payload;
        const postIndex = state.explorePosts.findIndex(
          (post) => post.id === postId
        );
        if (postIndex !== -1) {
          state.explorePosts[postIndex].post_comment = state.explorePosts[postIndex].post_comment.filter(
            (comment) => comment.id !== commentId
          );
        }
      });
  },
});

export default explorePostsSlice.reducer;
