import LocalStorageService from "../../../../utils/localstorage-services";
import {
  login,
  verifytotp,
  logout,
  verifyIdentity,
  resendIdentityVerificationEmail,
} from "../../../services/auth/session/auth.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeModal } from "../../../slices/common/modal";
import axiosClient from "../../../../utils/api";
import { getMessaging, getToken } from "firebase/messaging";

// Add cleanup function
const cleanupBrowserData = async () => {
  // Clear service workers (except Firebase)
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (let registration of registrations) {
      // Skip Firebase service worker
      if (registration.scope.includes('firebase-messaging') || 
          registration.scope.includes('firebase-cloud-messaging')) {
        continue;
      }
      await registration.unregister();
    }
  }

  // Clear localStorage
  LocalStorageService.removeFromLocalStorage("user-details");
  LocalStorageService.removeFromLocalStorage("user-details-admin");

  // Clear cache if supported (except Firebase)
  if ('caches' in window) {
    try {
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map(name => {
        // Skip Firebase related caches
        if (name.includes('firebase')) {
          return Promise.resolve();
        }
        return caches.delete(name);
      }));
    } catch (err) {
      console.error('Cache cleanup failed:', err);
    }
  }
};

export const verifyTOTPAction = createAsyncThunk(
  "auth/verifytotp",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await verifytotp(payload);
      
      if (!response.status || response.status !== 1) {
        return rejectWithValue(response);
      }
      
      return fulfillWithValue(response);
    } catch (error) {
      return rejectWithValue(error.response?.data || error);
    }
  }
);

export const loginAction = createAsyncThunk(
  "auth/login",
  async (payload, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await login(payload);
      
      if (!response.status) {
        return rejectWithValue(response);
      }

      try {
        const messaging = getMessaging();
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        });
        if (token) {
          await axiosClient.post('/notifications/register-token', { fcmToken: token });
        }
      } catch (error) {
        console.error('Failed to register FCM token:', error);
      }

      dispatch(closeModal());
      
      if (response.message === "Two-factor authentication required") {
        localStorage.setItem('temp_2fa_token', response.response.tempToken);
        localStorage.setItem('requires_2fa', JSON.stringify({ app: true }));
      }
      
      return fulfillWithValue(response);
    } catch (error) {
      return rejectWithValue(error.response?.data || error);
    }
  }
);

export const logoutAction = createAsyncThunk(
  "auth/logout",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await logout();
      if (!response.status || response.status !== 1) {
        return rejectWithValue(response);
      }
      await cleanupBrowserData();
      window.location.href = '/login';
      return fulfillWithValue(response);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyIdentityAction = createAsyncThunk(
  "auth/verifyIdentity",
  async (token, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await verifyIdentity(token);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue({ message: res.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const resendIdentityVerificationEmailAction = createAsyncThunk(
  "auth/resendIdentityVerificationEmail",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await resendIdentityVerificationEmail(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue({ message: res.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
